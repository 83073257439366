:root {
  --deskWidth: 1122px;
  --deskSpace: 120px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1023px) {
  :root {
    --deskSpace: 40px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
:root {
  --spacePart: 15px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 15px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 15px;
  }
}
:root {
  --spaceUnit: 25px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 15px;
  }
}
:root {
  --spaceTotal: 40px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 40px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "soehne-breit-kraftig";
  src: url("/extras/fonts/soehne-breit-kraftig.woff2") format("woff2");
}
@font-face {
  font-family: "soehne-kraftig";
  src: url("/extras/fonts/soehne-kraftig.woff2") format("woff2");
}
@font-face {
  font-family: "soehne-leicht";
  src: url("/extras/fonts/soehne-leicht.woff2") format("woff2");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  font-size: 100%;
}
*::selection {
  background: #D26E46;
  color: #fff;
}
.hidden,
.cb-invisible {
  display: none !important;
}
li {
  list-style-position: inside;
}
img,
audio,
video,
iframe {
  float: left;
  width: 100%;
}
strong {
  font-weight: normal;
  font-family: 'soehne-kraftig', sans-serif;
}
sup,
sub {
  position: relative;
  vertical-align: baseline;
  top: -0.8em;
  font-size: 70%;
}
sub {
  top: 0.4em;
}
address {
  font-style: normal;
}
input,
textarea {
  border-radius: 0;
  font-size: 18px;
  font-family: 'soehne-leicht', sans-serif;
  line-height: 1.33333333;
}
.unit caption {
  display: none;
}
.flag {
  background: #D26E46;
  color: #fff;
}
img.cb-loading {
  background-size: 30px 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader.svg);
}
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
.area {
  float: left;
  width: 100%;
}
#view .area:empty {
  display: none;
}
.unit {
  float: left;
  width: 100%;
  margin-top: var(--spaceUnit);
  margin-bottom: var(--spaceUnit);
  position: relative;
}
.unit__content {
  float: left;
  width: 100%;
}
.unit__head,
.unit__body,
.unit__foot {
  float: left;
  width: 100%;
}
.unit--fold div.less,
.unit--fold div.more {
  float: left;
  width: 100%;
}
.unit--fold .ctrl {
  float: left;
  width: 100%;
}
.part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  position: relative;
}
.pict a {
  float: left;
  width: 100%;
}
.pict img {
  width: 100%;
}
.pict img:not(.svg) {
  width: auto;
  max-width: 100% !important;
}
#root#root#root img.zoom {
  display: none !important;
}
#edit .part video {
  pointer-events: none;
}
.cb-image-figure {
  float: left;
  width: 100%;
}
.cb-image-container {
  float: left;
  width: 100%;
  position: relative;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 6px;
}
#disp.zoom .cb-image-figure {
  height: 100%;
}
#disp.zoom .cb-image-container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom .cb-image-caption {
  display: none;
}
.cb-album .cb-image-figure {
  opacity: 1 !important;
  display: block !important;
}
.cb-album .cb-image-container {
  position: static;
}
.cb-album .cb-image-caption {
  display: none;
}
div.load {
  background: none !important;
}
div.load a.load {
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 55px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 15px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-down-black.svg);
}
@media (hover: hover) and (pointer: fine) {
  div.load a.load:active,
  div.load a.load:hover,
  div.load a.load:focus {
    background-color: #D26E46;
    background-size: 15px;
    background-position: calc(100% - 20px) calc(50% + 5px);
  }
}
.show a {
  float: left;
  width: 100%;
  display: block;
}
#edit .line {
  padding-top: 5px;
  padding-bottom: 5px;
}
.line hr {
  float: left;
  width: 100%;
  border-bottom: 1px solid #000;
}
.line hr.bold {
  border-bottom-width: 2px;
}
.line hr.dash {
  border-bottom-style: dashed;
}
.line hr.spot {
  border-bottom-style: dotted;
}
#edit div.code {
  position: relative;
  min-height: 30px;
}
#edit div.code:after {
  content: 'Code';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: #aaa;
  opacity: 0.6;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'soehne-kraftig', sans-serif;
  font-size: 11px;
  letter-spacing: 0.2em;
  padding: 0 20px;
}
.cb-code-executable {
  float: left;
  width: 100%;
}
.cb-service {
  float: left;
  width: 100%;
  margin: 5px 0;
}
.cb-service:first-child {
  margin-top: 0;
}
.cb-service:last-child {
  margin-bottom: 0;
}
.cb-youtube iframe,
.cb-vimeo iframe {
  height: 100%;
  background-color: #fff;
}
.text-section {
  float: left;
  width: 100%;
}
.list {
  float: left;
  width: 100%;
}
.list--bullet li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered {
  counter-reset: numberedList;
}
.list--numbered li {
  list-style: none;
  padding-left: 24px;
  position: relative;
}
.list--numbered li:before {
  content: counter(numberedList) ".";
  counter-increment: numberedList;
  position: absolute;
  left: 0;
  top: 0;
}
#expo {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
#slides {
  float: left;
  width: 100%;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative;
}
#slides .slide {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
}
#slides a {
  display: block;
  width: 100%;
  height: 100%;
}
#slides a.null {
  cursor: default;
}
.cb-slides .cb-image-figure {
  height: 100%;
}
.cb-slides .cb-image-container {
  height: 100% !important;
}
#slides img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: cover;
  object-position: center;
}
#expo div.link {
  display: none;
  float: left;
  width: 100%;
  height: 0;
}
#expo a.link {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 30px;
  height: 50px;
  transform: translateY(-50%);
  background: #D26E46;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#expo a.link.prev {
  left: 20px;
}
#expo a.link.next {
  right: 20px;
}
table.link,
table.link tbody {
  float: left;
  display: block;
  width: 100%;
  height: 0;
}
.cb-index-all {
  display: block;
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 2;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-index-all td {
  float: left;
  display: block;
  margin: 0 10px;
}
.cb-index-all td.init {
  margin-left: 0;
}
.cb-index-all td.exit {
  margin-right: 0;
}
.cb-index-all a {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
}
.cb-index-all td.this a {
  background: #D26E46;
}
.cb-index-some {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
.cb-index-some td {
  float: left;
  display: block;
  color: #fff;
}
.cb-index-some td:first-child:after {
  display: inline-block;
  content: '/';
  margin: 0 5px;
}
.cb-index-some a {
  color: #fff;
}
.cb-index-some a:hover,
.cb-index-some a:focus {
  color: #fff;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.8);
}
#disp {
  position: absolute;
  z-index: 5001;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 1000px !important;
  max-width: 90%;
  min-height: 100vh;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  box-sizing: border-box;
  padding: 80px 0 !important;
  font-size: 14px;
  font-family: 'soehne-leicht', sans-serif;
  line-height: 1.42857143;
  color: #000;
}
@media (max-width: 767px) {
  #disp {
    padding: 20px 0 !important;
  }
}
.disp-wrapper {
  float: left;
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 40px;
}
@media (max-width: 767px) {
  .disp-wrapper {
    padding: 15px;
  }
}
#disp form {
  float: left;
}
.disp-wrapper > form {
  width: 100%;
}
@media (max-width: 767px) {
  #disp form {
    width: 100%;
  }
}
#disp .fail {
  color: #ae2121;
}
#disp .part {
  margin-top: 4px;
  margin-bottom: 4px;
}
#disp a.mail {
  float: left;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
#disp .head,
#disp .body,
#disp .foot {
  float: left;
  width: 100%;
  position: relative;
}
#disp .body {
  margin: 20px 0;
}
#disp .foot form + form {
  float: right;
}
#disp h2 {
  font-size: 14px;
  line-height: 1.71428571;
  font-weight: normal;
  text-transform: uppercase;
  color: #D26E46;
  width: 100%;
  box-sizing: border-box;
  padding-right: 30px;
}
#disp h3 {
  color: #D26E46;
  font-size: 16px;
  font-weight: normal;
  font-family: 'soehne-kraftig', sans-serif;
  padding: 20px 0;
  width: 100%;
}
#disp .head .ctrl {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}
#disp .head .ctrl > div {
  float: left;
}
#disp .head .ctrl a {
  float: left;
  display: block;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 16px;
  height: 24px;
  background-size: 16px 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
#disp .foot input,
#disp .foot a {
  float: left;
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 85px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
  line-height: 1.42857143;
}
@media (hover: hover) and (pointer: fine) {
  #disp .foot input:active,
  #disp .foot a:active,
  #disp .foot input:hover,
  #disp .foot a:hover,
  #disp .foot input:focus,
  #disp .foot a:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
}
#disp .foot input.next,
#disp .foot a.next {
  float: right;
}
#disp.mail .foot input {
  float: right;
}
@media (max-width: 767px) {
  #disp .foot input,
  #disp .foot a {
    width: 100%;
    margin: 5px 0;
  }
  #disp .foot input:first-child,
  #disp .foot a:first-child {
    margin-top: 0;
  }
  #disp .foot input:last-child,
  #disp .foot a:last-child {
    margin-bottom: 0;
  }
}
#disp fieldset {
  float: left;
  width: 100%;
  position: relative;
  margin: 4px 0;
}
#disp fieldset.tiny {
  width: 48%;
}
#disp .head fieldset,
#disp .foot fieldset {
  margin: 0;
}
@media (max-width: 767px) {
  #disp fieldset.tiny {
    width: 100%;
  }
}
#disp label.name {
  float: left;
  width: 30%;
  margin-right: 4%;
  min-height: 26px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 767px) {
  #disp label.name {
    width: 100%;
    margin-right: 0;
  }
}
#disp input.text,
#disp textarea {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  min-height: 26px;
  box-sizing: border-box;
  padding: 2px 10px;
}
#disp input.text.fail,
#disp textarea.fail {
  border: 2px solid #ae2121;
}
#disp textarea {
  min-height: 100px;
  padding: 6px 10px;
}
@media (max-width: 767px) {
  #disp input.text,
  #disp textarea {
    width: 100%;
  }
}
#disp select {
  float: right;
  width: 66%;
  background-color: #F0F0F0;
  height: 26px;
}
@media (max-width: 767px) {
  #disp select {
    width: 100%;
  }
}
#disp input + label,
#disp input + label {
  display: block;
  padding-left: 30px;
}
#disp input.radio,
#disp input.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 20px;
  width: 20px;
}
.cb-sitemap-target {
  float: left;
  margin-bottom: 20px;
  color: #fff;
  padding: 5px 10px;
  background-color: #ae2121;
}
.cb-sitemap-contents {
  float: left;
  width: 100%;
}
.cb-sitemap-contents li {
  list-style: none;
  padding-left: 20px;
  position: relative;
}
.cb-sitemap-contents li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.cb-zoom-indicator {
  position: absolute;
  z-index: 2;
  right: 15px;
  bottom: 15px;
  width: 36px;
  height: 36px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  background-size: 18px 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-zoom-white.svg);
  opacity: 0;
  transform: scale(0);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
a.zoom .cb-zoom-indicator {
  opacity: 1;
  transform: scale(1);
}
@media (max-width: 1023px) {
  .cb-zoom-indicator {
    right: 10px;
    bottom: 10px;
    width: 24px;
    height: 24px;
    background-size: 12px 12px;
    opacity: 1;
    transform: scale(1);
  }
}
#disp.zoom {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  max-width: 100%;
  height: 100%;
  background: none;
  padding: 0 !important;
  display: block !important;
  transform: none;
}
#disp.zoom .disp-wrapper {
  padding: 0;
  background: none;
  height: 100%;
}
#disp.zoom div.head h2 {
  display: none;
}
#disp.zoom div.head div.ctrl {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 2;
}
@media (max-width: 767px) {
  #disp.zoom div.head div.ctrl {
    top: 20px;
    right: 20px;
  }
}
#disp.zoom div.head div.ctrl > div {
  float: left;
  margin: 0 10px;
}
#disp.zoom div.head div.ctrl > div:first-child {
  margin-left: 0;
}
#disp.zoom div.head div.ctrl > div:last-child {
  margin-right: 0;
}
#disp.zoom div.head div.ctrl a {
  float: left;
  display: block;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
}
#disp.zoom div.head div.ctrl .next a {
  background-image: url(/images/cb-default/cb-arrow-right-white.svg);
}
#disp.zoom div.head div.ctrl .quit a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
  background-size: 16px 16px;
}
#disp.zoom div.head div.ctrl a:hover {
  transform: scale(1.1);
}
#disp.zoom div.head div.ctrl a:active {
  transform: scale(1);
}
#disp.zoom div.body {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 80px 30px;
  margin: 0;
}
@media (max-width: 767px) {
  #disp.zoom div.body {
    padding: 60px 20px;
  }
}
#disp.zoom div.body > div {
  float: left;
  width: 100% !important;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body a {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#disp.zoom div.body img {
  float: left;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#disp.zoom div.foot {
  display: none;
}
.ie11 #disp.zoom {
  position: absolute;
}
.ie11 #disp.zoom div.body,
.ie11 #disp.zoom div.body > div,
.ie11 #disp.zoom div.body a {
  height: auto;
  display: block;
}
.ie11 #disp.zoom div.body img {
  float: none;
  position: relative;
  height: auto;
  display: block;
  margin: 0 auto;
}
#disp.srch {
  display: block !important;
}
#disp.srch h2 {
  display: none;
}
#disp.srch .disp-wrapper {
  background: none;
  padding: 0;
}
#disp.srch .head .ctrl a {
  background-image: url(/images/cb-default/cb-cross-white.svg);
}
#disp.srch div.body {
  margin: 0;
}
#disp.srch fieldset {
  margin: 0;
}
#disp.srch label.name {
  display: none;
}
#disp.srch input.text {
  font-family: 'soehne-leicht', sans-serif;
  background-color: transparent;
  margin-top: 40px;
  width: 100%;
  color: #fff;
  font-size: 50px;
  line-height: 1;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}
@media (max-width: 767px) {
  #disp.srch input.text {
    font-size: 18px;
  }
}
.cb-result {
  float: left;
  width: 100%;
  color: #fff;
  font-size: 18px;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}
.cb-result p {
  margin-top: 40px;
}
.cb-result a {
  color: #fff;
  text-decoration: none;
}
.cb-result a:hover {
  color: #fff;
  text-decoration: underline;
}
.cb-result table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}
.cb-result tbody {
  width: 100%;
}
.cb-result th {
  padding-top: 20px;
  font-size: 24px;
  line-height: 1;
  text-align: left;
}
.cb-result th:first-child {
  padding-right: 40px;
  width: 80px;
}
.cb-result td {
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}
.cb-result th,
.cb-result td {
  vertical-align: middle;
}
@media (max-width: 767px) {
  .cb-result {
    font-size: 14px;
  }
  .cb-result th {
    padding-top: 10px;
    font-size: 18px;
  }
  .cb-result th:first-child {
    padding-right: 10px;
    width: 54px;
  }
  .cb-result td {
    padding-bottom: 10px;
    padding-top: 5px;
  }
}
.unit.form form {
  float: left;
  width: 100%;
}
.cb-form-required {
  float: left;
  width: 100%;
}
.unit.form fieldset {
  float: left;
  width: 100%;
  position: relative;
}
.unit.form div.ctrl {
  float: left;
  width: 100%;
}
.unit.form div.ctrl.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
@media (min-width: 768px) {
  .unit.form .tile div.ctrl {
    float: right;
    width: 66%;
  }
}
.unit.form .name {
  float: left;
  width: 100%;
  padding-bottom: 5px;
}
@media (min-width: 768px) {
  .unit.form .tile .name {
    width: 30%;
    min-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
}
.unit.form input.text,
.unit.form textarea {
  float: left;
  width: 100%;
  box-sizing: border-box;
  background: #F0F0F0;
  min-height: 40px;
  padding: 5px 10px;
  -webkit-appearance: none;
}
.unit.form input.text.fail,
.unit.form textarea.fail {
  background-color: #e7bcbc;
}
.unit.form textarea {
  padding: 10px;
}
@media (min-width: 768px) {
  .unit.form .tile input.text,
  .unit.form .tile textarea {
    float: right;
    width: 66%;
  }
}
.unit.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  padding: 4px 0;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile div.ctrl > div {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.unit.form div.tick label {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 26px;
}
.unit.form div.tick input {
  position: absolute;
  left: 0;
  top: 4px;
  width: 18px;
  margin-right: 6px;
  height: 24px;
  vertical-align: top;
}
@media (min-width: 768px) {
  .unit.form div.tick.tile input {
    top: 8px;
  }
}
.unit.form select {
  float: left;
  width: 100%;
  font-family: 'soehne-leicht', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  background: #F0F0F0;
  height: 40px;
  border: 1px solid #000;
  padding: 5px;
}
.unit.form .ship fieldset > div {
  float: left;
  width: 100%;
}
.unit.form .ship fieldset > div.fail {
  box-sizing: border-box;
  padding: 5px;
  background-color: #e7bcbc;
}
.unit.form .ship div.chop {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.unit.form .ship div.chop input {
  float: left;
  display: block;
  font-size: 12px;
}
.unit.form .ship div.chop span {
  float: left;
}
.unit.form .ship div.chop a {
  float: left;
  display: block;
  margin-left: 10px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  width: 12px;
  height: 12px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-cross-black.svg);
}
@media (min-width: 768px) {
  .unit.form .ship.tile fieldset > div {
    float: right;
    width: 66%;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}
.unit.form input.submit {
  float: right;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 85px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
@media (hover: hover) and (pointer: fine) {
  .unit.form input.submit:active,
  .unit.form input.submit:hover,
  .unit.form input.submit:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
}
.calendar {
  position: absolute !important;
  z-index: 2;
  left: 34% !important;
  top: 100% !important;
  right: unset !important;
  background: #fff;
  width: 200px;
  margin: 10px 0 0 0 !important;
  box-shadow: 0 0 6px rgba(160, 160, 160, 0.4);
  font-size: 14px;
  line-height: 1.14285714;
  padding: 20px;
}
.calendar table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.calendar th,
.calendar td {
  text-align: center;
  padding: 2px;
}
.calendar tr.month th {
  padding-bottom: 10px;
}
.calendar tr.month th:first-child a,
.calendar tr.month th:last-child a {
  float: left;
  display: block;
  width: 16px;
  height: 16px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  color: transparent;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-left-black.svg);
}
.calendar tr.month th:last-child a {
  float: right;
  background-image: url(/images/cb-default/cb-arrow-right-black.svg);
}
.calendar td.today a {
  color: #000;
  text-decoration: underline;
}
.unit.form .recaptcha-info {
  color: #aaa;
}
.unit.form .recaptcha-info a {
  color: #aaa;
}
.unit.form .recaptcha-info a:hover,
.unit.form .recaptcha-info a:focus {
  text-decoration: underline;
}
.unit.form .part.fail {
  font-size: 16px;
  line-height: 1.25;
  border-left: 4px solid #ae2121;
  box-sizing: border-box;
  padding: 20px;
  background-color: #e7bcbc;
  color: #ae2121;
}
.unit.form div.cb-form-sent {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.unit.form div.cb-form-sent a {
  float: right;
  color: #5abb55;
  font-size: 14px;
}
#disp.two-step-verification div.body {
  overflow: hidden;
}
#disp.two-step-verification p {
  float: left;
  width: 100%;
}
.two-step-verification-container {
  float: left;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  height: 350px;
  position: relative;
}
.two-step-verification-container a {
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 85px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
@media (hover: hover) and (pointer: fine) {
  .two-step-verification-container a:active,
  .two-step-verification-container a:hover,
  .two-step-verification-container a:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
}
.two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  background-size: 100px 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-loader-black.svg);
}
.two-step-verification-container.loaded .two-step-verification__div {
  background-image: none;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 10px;
  vertical-align: top;
}
.table th.init {
  padding-left: 0;
}
.table th.exit {
  padding-right: 0;
}
.table th.align-left {
  text-align: left;
}
.table th.align-center {
  text-align: center;
}
.table th.align-right {
  text-align: right;
}
.table--headline th {
  font-weight: normal;
  font-family: 'soehne-kraftig', sans-serif;
}
.table td {
  padding: 5px 10px;
  vertical-align: top;
}
.table td.init {
  padding-left: 0;
}
.table td.exit {
  padding-right: 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: normal;
  font-family: 'soehne-kraftig', sans-serif;
}
@media (max-width: 767px) {
  .part--table .table {
    font-size: 2.34680574vw;
  }
}
html,
body {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#home {
  float: left;
  height: 19px;
  margin: 20px 0 21px;
  transition: all 0.4s ease-in;
}
body.cb-toggle-target-active #home {
  opacity: 0;
  pointer-events: none;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
}
.layout5 #head {
  margin-top: calc(var(--spaceTotal) * 1);
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'soehne-leicht', sans-serif;
  font-size: 18px;
  line-height: 1.33333333;
  color: #000;
  overflow: hidden;
  hyphens: none;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
#view .wrapper {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}
#view.show-content .wrapper {
  opacity: 1;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: var(--deskMaxWidth);
  width: var(--deskWidth);
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.section--header .desk {
  --deskWidth: calc(100% - 100px);
  --deskSpace: 50px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 1023px) {
  .section--header .desk {
    --deskWidth: calc(100% - 40px);
    --deskSpace: 20px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
.container--masonry .desk,
.section--two .desk {
  width: 1366px;
}
.mobile-navigation .desk {
  --deskWidth: 1088px;
  --deskSpace: 200px;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
@media (max-width: 767px) {
  .mobile-navigation .desk {
    --deskSpace: 70px;
    --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
  }
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header {
  position: fixed;
  z-index: 2000;
  top: 0;
}
body:not(.cb-toggle-target-active) .section--header {
  background-color: #fff;
}
main {
  float: left;
  width: 100%;
  position: relative;
  flex: 1 0 auto;
}
.container {
  float: left;
  width: 100%;
  isolation: isolate;
}
.container--mood {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  min-height: calc(100vh - 60px - 100px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc(100svh - 60px - 100px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
}
.layout2 .container--mood,
.layout3 .container--mood,
.layout4 .container--mood {
  justify-content: center;
  min-height: calc(100vh - 60px - 100px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: calc(100svh - 60px - 100px);
  /* Use vh as a fallback for browsers that do not support Custom Properties */
}
.container--mood .mood {
  width: calc(100% - var(--deskSpace));
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.container--mood .sitename {
  width: var(--deskSpace);
  height: 100vh;
  height: 100svh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 12px;
  box-sizing: border-box;
}
.container--mood .sitename__content {
  float: left;
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: 0 0;
}
@media (max-width: 1023px) {
  .container--mood .sitename__content {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
.container--mood .sitename__content a {
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  color: #000;
  font-size: 12px;
  line-height: 1.16666667;
  letter-spacing: 0.001em;
  white-space: nowrap;
  display: inline-block;
  pointer-events: none;
}
body.sub2 .container--mood .sitename .sitename__text {
  display: none;
}
.container--mood .content {
  float: left;
  width: 100%;
  padding-left: calc(var(--deskSpace) * 2 + 10px);
  padding-right: var(--deskSpace);
  padding-bottom: 20px;
  box-sizing: border-box;
}
.designelement {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}
.designelement--navi {
  width: 350px;
  left: -50px;
  bottom: 200px;
}
.designelement--footLeft {
  width: 300px;
  left: -9px;
  top: 140px;
}
@media (max-width: 767px) {
  .designelement--footLeft {
    top: 180px;
  }
}
.designelement--footRight {
  width: 322px;
  right: -55px;
  top: 160px;
}
@media (max-width: 767px) {
  .designelement--footRight {
    top: 250px;
  }
}
.designelement--kontakt {
  width: 70vw;
  right: -55px;
  top: 200px;
}
@media (max-width: 767px) {
  .designelement--kontakt {
    top: 110px;
  }
}
@keyframes pulpoPulse {
  0%,
  100% {
    transform: rotate(0) scale(1.2) translateX(0);
    perspective: -100px;
  }
  50% {
    transform: rotate(-12deg) scale(0.95) translateX(20px);
    perspective: 150px;
  }
}
@keyframes pulpoPulseRight {
  0%,
  100% {
    transform: rotate(-5deg) scale(1.3) translateX(-10px);
    perspective: 0;
  }
  50% {
    transform: rotate(-20deg) scale(1) translateX(25px);
  }
}
.section--hero {
  isolation: isolate;
}
#navilinks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 51.62337662%;
  box-sizing: border-box;
  gap: 20px;
  margin-bottom: 40px;
}
#navilinks .meta {
  float: left;
  font-size: 18px;
  line-height: 1.55555556;
  letter-spacing: 0.002em;
  color: #000;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  #navilinks .meta:hover,
  #navilinks .meta:focus {
    color: #D26E46;
  }
}
.cbdModule--masonryTitle {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.cbdModule--teamTitle {
  margin-top: calc(var(--spaceTotal) * 2);
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.section--footer {
  flex-shrink: 0;
  background-color: #BEB9AF;
  isolation: isolate;
}
.footarea {
  float: left;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 20px;
}
.footpart {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.footpart a {
  color: #000;
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .footpart a:hover,
  .footpart a:focus {
    color: #D26E46;
  }
}
.getintouch {
  float: left;
  width: 100%;
  margin-bottom: 240px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.getintouch .email,
.getintouch .meta {
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.16666667;
  letter-spacing: 0.002em;
}
.vcard {
  float: left;
  width: 100%;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.16666667;
  letter-spacing: 0.002em;
}
#legal {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
#legal .meta {
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.16666667;
  letter-spacing: 0.002em;
}
a {
  color: #D26E46;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #D26E46;
}
.part.link {
  margin-top: calc(var(--spaceTotal) * 1);
}
.part.link .open {
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 85px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
@media (hover: hover) and (pointer: fine) {
  .part.link .open:active,
  .part.link .open:hover,
  .part.link .open:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
}
.backlink {
  float: left;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 30px 16px 85px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: 20px 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-black.svg);
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 2);
}
@media (hover: hover) and (pointer: fine) {
  .backlink:active,
  .backlink:hover,
  .backlink:focus {
    color: #000;
    background-color: #D26E46;
    background-size: 35px;
    background-position: 10px 50%;
  }
}
h1 {
  font-size: 40px;
  line-height: 1;
  letter-spacing: 0.001em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  hyphens: manual;
  font-weight: ;
}
.layout1 h1 {
  color: #fff;
}
.layout5 h1 {
  font-size: 24px;
  line-height: 1.33333333;
}
h2 {
  font-size: 24px;
  line-height: 1.33333333;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
}
h3 {
  font-size: 18px;
  line-height: 1.33333333;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
}
.ns-slider__quoteText,
.ns-slider__part--sliderText p,
p.loud {
  font-size: 18px;
  line-height: 1.33333333;
}
.ns-slider__quoteAuthor,
p.pale {
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
}
.cbdModule--footerText {
  margin-bottom: 40px;
}
.footerText {
  float: left;
  font-size: 18px;
  line-height: 1.33333333;
}
.area--intro {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.area--one {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.layout4 .area--one.area1 {
  margin-top: calc(var(--spaceTotal) * 1);
}
.area--two {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.area--one .unit.unit--isAnchor {
  color: #000;
}
@media (hover: hover) and (pointer: fine) {
  .area--one .unit.unit--isAnchor:hover .part.link .open,
  .area--one .unit.unit--isAnchor:focus .part.link .open {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
  .area--one .unit.unit--isAnchor:hover .part.load .load,
  .area--one .unit.unit--isAnchor:focus .part.load .load {
    cursor: pointer;
    color: #000;
    border: 1px solid #000;
    padding: 14px 55px 16px 30px;
    border-radius: 35px;
    -webkit-appearance: none;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 0.002em;
    font-family: 'soehne-breit-kraftig', sans-serif;
    font-weight: normal;
    display: inline-block;
    transition: all 0.4s ease-in;
    background-size: 15px;
    background-position: calc(100% - 20px) 50%;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-down-black.svg);
  }
}
@media (hover: hover) and (pointer: fine) and (hover: hover) and (pointer: fine) {
  .area--one .unit.unit--isAnchor:hover .part.load .load:active,
  .area--one .unit.unit--isAnchor:focus .part.load .load:active,
  .area--one .unit.unit--isAnchor:hover .part.load .load:hover,
  .area--one .unit.unit--isAnchor:focus .part.load .load:hover,
  .area--one .unit.unit--isAnchor:hover .part.load .load:focus,
  .area--one .unit.unit--isAnchor:focus .part.load .load:focus {
    background-color: #D26E46;
    background-size: 15px;
    background-position: calc(100% - 20px) calc(50% + 5px);
  }
}
.area--one .unit.unit--sideimage .unit__background {
  float: left;
  width: 100%;
  isolation: isolate;
  position: relative;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.area--one .unit.unit--sideimage .unit__background:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #D26E46;
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .area--one .unit.unit--sideimage .unit__background:hover:after,
  .area--one .unit.unit--sideimage .unit__background:focus:after {
    opacity: 0!important;
  }
}
@media (hover: hover) and (pointer: fine) {
  .area--one .unit.unit--sideimage.unit--isAnchor:hover .unit__background:after,
  .area--one .unit.unit--sideimage.unit--isAnchor:focus .unit__background:after {
    opacity: 0.4;
  }
}
.area--one .unit.unit--sideimage .head,
.area--one .unit.unit--sideimage .foot {
  display: none;
}
.area--one .unit--gallery {
  margin-top: 0;
  margin-bottom: 0;
}
.area--two .unit {
  background-color: #BEB9AF;
}
.area--two .unit.unit--isAnchor {
  color: #000;
}
@media (hover: hover) and (pointer: fine) {
  .area--two .unit.unit--isAnchor:hover .part.link .open,
  .area--two .unit.unit--isAnchor:focus .part.link .open {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
  .area--two .unit.unit--isAnchor:hover .part.load .load,
  .area--two .unit.unit--isAnchor:focus .part.load .load {
    cursor: pointer;
    color: #000;
    border: 1px solid #000;
    padding: 14px 55px 16px 30px;
    border-radius: 35px;
    -webkit-appearance: none;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 0.002em;
    font-family: 'soehne-breit-kraftig', sans-serif;
    font-weight: normal;
    display: inline-block;
    transition: all 0.4s ease-in;
    background-size: 15px;
    background-position: calc(100% - 20px) 50%;
    background-repeat: no-repeat;
    background-image: url(/images/arrow-down-black.svg);
  }
}
@media (hover: hover) and (pointer: fine) and (hover: hover) and (pointer: fine) {
  .area--two .unit.unit--isAnchor:hover .part.load .load:active,
  .area--two .unit.unit--isAnchor:focus .part.load .load:active,
  .area--two .unit.unit--isAnchor:hover .part.load .load:hover,
  .area--two .unit.unit--isAnchor:focus .part.load .load:hover,
  .area--two .unit.unit--isAnchor:hover .part.load .load:focus,
  .area--two .unit.unit--isAnchor:focus .part.load .load:focus {
    background-color: #D26E46;
    background-size: 15px;
    background-position: calc(100% - 20px) calc(50% + 5px);
  }
}
.area--two .unit .unit__body {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .area--two .unit .unit__body {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.area--two .unit .head,
.area--two .unit .foot {
  display: none;
}
.area--masonry .unit.unit--isAnchor {
  color: #000;
}
.area--masonry .unit .part.pict:first-child {
  float: left;
  width: 100%;
  isolation: isolate;
  position: relative;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.area--masonry .unit .part.pict:first-child:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: #D26E46;
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .area--masonry .unit.unit--isAnchor:hover .part.pict:first-child:after,
  .area--masonry .unit.unit--isAnchor:focus .part.pict:first-child:after {
    opacity: 0.4;
  }
}
.area--masonry .unit .head,
.area--masonry .unit .foot {
  display: none;
}
#view #head {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-in-out;
}
#view #head.is-intersecting {
  opacity: 1;
  transform: translateX(0);
}
#view .unit:not(.unit--articleMasonry) .unit__background,
#view .unit:not(.unit--articleMasonry) .part {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease-in-out;
}
#view .unit:not(.unit--articleMasonry) .unit__background,
#view .unit:not(.unit--articleMasonry) .part.pict {
  transition-delay: 0.2s;
}
#view .unit:not(.unit--articleMasonry).is-intersecting .unit__background,
#view .unit:not(.unit--articleMasonry).is-intersecting .part {
  opacity: 1;
  transform: translateX(0);
}
@media (max-width: 1023px) {
  body.cb-toggle-target-active {
    overflow: hidden;
  }
}
.togglenavigation {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 2001;
  transform: translateY(-50%);
  width: 50px;
  height: 10px;
  cursor: pointer;
}
.togglenavigation:before {
  content: 'Menü';
  position: absolute;
  left: calc(-100% - 14px);
  top: -4px;
  color: #000;
  transition: all 0.4s ease-in;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  letter-spacing: 0.002em;
  font-size: 16px;
  line-height: 1.125;
}
.togglenavigation:hover:before,
.togglenavigation:focus:before {
  color: #D26E46;
}
.togglenavigation:hover .tline,
.togglenavigation:focus .tline {
  background-color: #D26E46;
}
.tline {
  position: absolute;
  top: calc(50% - 4px);
  transform: translateY(-50%);
  left: 0;
  width: 50px;
  height: 1px;
  background-color: #000;
  border-radius: 1px;
  transition: all 0.4s ease-in;
}
.tline--2 {
  top: calc(50% + 4px);
}
body.cb-toggle-target-active .togglenavigation:before {
  opacity: 0;
}
body.cb-toggle-target-active .tline {
  top: 50%;
}
body.cb-toggle-target-active .tline--2 {
  opacity: 0;
}
.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: #BEB9AF;
  overflow-y: scroll;
  isolation: isolate;
  transform: translateY(-100%);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navigation {
  transform: translateY(0);
}
.mobile-navi-animation {
  float: left;
  width: 100%;
  min-height: 100vh;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  min-height: 100svh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(-100vh);
  transition: all 0s 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
body.cb-toggle-target-active .mobile-navi-animation {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(0);
  opacity: 1;
}
.mobile-navigation div.navi {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
}
.mobile-navigation div.navi > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item {
  float: left;
  width: 100%;
  position: relative;
}
@media (min-width: 767px) {
  .mobile-navigation div.navi > .item:nth-child(3n+1) {
    padding-left: 16.91176471%;
    box-sizing: border-box;
  }
  .mobile-navigation div.navi > .item:nth-child(3n+3) {
    padding-left: 33.82352941%;
    box-sizing: border-box;
  }
}
.mobile-navigation div.navi > .item.item-empty > .cb-toggle {
  display: none;
}
.mobile-navigation div.navi > .item > .menu {
  display: block;
  color: #000;
  transition: all 0.4s ease-in;
}
.mobile-navigation div.navi > .item > .menu.path {
  color: #D26E46;
}
@media (hover: hover) and (pointer: fine) {
  .mobile-navigation div.navi > .item > .menu:hover,
  .mobile-navigation div.navi > .item > .menu:focus {
    color: #D26E46;
  }
}
.mobile-navigation div.sub1 {
  box-sizing: border-box;
  margin: 80px 0 60px;
}
@media (max-width: 1023px) {
  .mobile-navigation div.sub1 {
    margin: 113px 0 40px;
  }
}
.mobile-navigation div.sub1 > .item > .menu {
  font-size: clamp(5rem, -6.228rem + 17.54vw, 8.75rem);
  letter-spacing: 0.001em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  padding-right: 50px;
}
@media (max-width: 1023px) {
  .mobile-navigation div.sub1 > .item > .menu {
    font-size: clamp(2.5rem, 1.053rem + 6.17vw, 5rem);
  }
}
.mobile-navigation div.sub2 {
  display: none;
}
.mobile-navigation div.sub2 {
  overflow: hidden;
  max-height: 0;
}
.mobile-navigation div.sub2 > .item {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi {
  transition: none;
  max-height: 1000px;
  transition: max-height 2s;
}
.mobile-navigation div.navi > .item.cb-toggle-target-active > div.navi > .item {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s;
}
.mobile-navigation .cb-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 24px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  transition: all 0.3s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.mobile-navigation .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
}
.ns-unitFilter__unit {
  display: none;
}
.ns-unitFilter__unit.ns-unitFilter__unit--activated {
  display: block;
  opacity: 0;
  transform: translateY(-20px) scale(0.98);
  transition: transform 0.4s, opacity 0.4s !important;
}
.ns-unitFilter__unit.ns-unitFilter__unit--active {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.ns-unitFilter {
  float: left;
  width: 100%;
  position: relative;
  z-index: 20;
  margin-bottom: calc(var(--spaceTotal) * 1);
}
.ns-unitFilter__wrapper {
  float: left;
  width: 100%;
}
.ns-unitFilter__toggle {
  display: none;
}
.ns-unitFilter__content {
  float: left;
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-between;
}
.ns-unitFilter__list {
  float: left;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
.ns-unitFilter__actions {
  float: left;
  display: flex;
  flex-wrap: wrap;
}
.ns-unitFilter__link {
  margin-bottom: 10px;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  font-size: 26px;
  line-height: 1.46153846;
  letter-spacing: 0.002em;
  color: #BEB9AF;
  cursor: pointer;
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .ns-unitFilter__link:hover,
  .ns-unitFilter__link:focus {
    color: #D26E46;
  }
}
.ns-unitFilter__link--reset {
  display: none;
}
@media (min-width: 1024px) {
  .ns-unitFilter__link--confirm {
    display: none;
  }
}
.ns-unitFilter__group {
  float: left;
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  margin-top: -1px;
}
.ns-unitFilter__group:first-child {
  margin-top: 0;
}
.ns-unitFilter__groupToggle {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 1.66666667;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 25px 20px 25px 0;
  box-sizing: border-box;
  position: relative;
  color: #000;
}
.ns-unitFilter__groupToggle:hover,
.ns-unitFilter__groupToggle:focus {
  color: #000;
}
.ns-unitFilter__groupToggle:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-style: solid;
  border-color: transparent;
  border-top-color: #000;
  border-width: 7px 5px 0 5px;
}
.ns-unitFilter__groupToggle.is-active:after {
  transform: translateY(-50%) rotate(-180deg);
}
.ns-unitFilter__groupContent {
  float: left;
  width: 100%;
  overflow: hidden;
  max-height: 0;
}
.ns-unitFilter__group.is-open .ns-unitFilter__groupContent {
  max-height: initial;
}
.ns-unitFilter__item {
  margin-bottom: 10px;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  font-size: 26px;
  line-height: 1.46153846;
  letter-spacing: 0.002em;
  color: #000;
  cursor: pointer;
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .ns-unitFilter__item:hover,
  .ns-unitFilter__item:focus {
    color: #D26E46;
  }
}
.ns-unitFilter__item.is-active {
  color: #D26E46;
}
@media (max-width: 1023px) {
  .ns-unitFilter__toggle {
    display: block;
    border: 1px solid #000;
    padding: 14px 85px 16px 30px;
    border-radius: 35px;
    -webkit-appearance: none;
    box-sizing: border-box;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.42857143;
    letter-spacing: 0.002em;
    font-family: 'soehne-breit-kraftig', sans-serif;
    font-weight: normal;
    cursor: pointer;
    background-size: 16px 16px;
    background-position: right 20px center;
    background-repeat: no-repeat;
    background-image: url(/images/cb-default/cb-arrow-down-black.svg);
  }
  .ns-unitFilter__toggle.is-active {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ns-unitFilter__content {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    background-color: #fff;
    max-height: 0;
    overflow-y: auto;
    box-sizing: border-box;
    display: unset;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .ns-unitFilter__content::-webkit-scrollbar {
    display: none;
  }
  .is-open .ns-unitFilter__content {
    max-height: initial;
    border: 1px solid #000;
    border-top: none;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
  }
  .ns-unitFilter__list {
    flex-direction: column;
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
  }
  .ns-unitFilter__actions {
    column-gap: 30px;
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
  }
  .ns-unitFilter__link {
    margin-right: 0;
    margin-bottom: 0;
  }
  .ns-unitFilter__group:first-child {
    border-top: none;
  }
  .ns-unitFilter__group:last-child {
    border-bottom: none;
  }
  .ns-unitFilter__groupToggle {
    padding-left: 40px;
    padding-right: 60px;
  }
  .ns-unitFilter__groupToggle:after {
    right: 40px;
  }
  .ns-unitFilter__groupContent {
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
  }
  .ns-unitFilter__item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
.ns-sliderWrapper {
  float: left;
  width: 100%;
  position: relative;
}
.ns-slider {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 2 + 20px);
  margin-bottom: calc(var(--spaceTotal) * 2 + 20px);
}
@media (max-width: 1023px) {
  .ns-slider {
    margin-top: calc(var(--spaceTotal) * 2);
    margin-bottom: calc(var(--spaceTotal) * 2);
  }
}
.ns-slider .ns-sliderIndex {
  float: left;
  width: 100%;
  margin-top: 40px;
}
.ns-slider .ns-sliderIndex__content {
  float: left;
  width: calc(100% + 20px);
  margin: -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.ns-slider .ns-sliderIndex__item {
  float: left;
  width: 16px;
  height: 16px;
  border-radius: 10000px;
  background-color: #BEB9AF;
}
.ns-slider .ns-sliderIndex__item.is-active {
  background-color: #D26E46;
}
.ns-slider .ns-sliderControl {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  width: 55px;
  height: 55px;
  cursor: pointer;
  border: 1px solid #000;
  border-radius: 100%;
}
.ns-slider .ns-sliderControl--prev {
  left: 0;
  background-size: 35px;
  background-position: 10px 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-left-black.svg);
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider .ns-sliderControl--prev:active,
  .ns-slider .ns-sliderControl--prev:hover,
  .ns-slider .ns-sliderControl--prev:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: 5px 50%;
  }
}
@media (max-width: 767px) {
  .ns-slider .ns-sliderControl--prev {
    position: relative;
    top: 0;
    transform: unset;
    float: left;
  }
}
.ns-slider .ns-sliderControl--next {
  right: 0;
  background-size: 35px;
  background-position: calc(100% - 10px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
  transition: all 0.4s ease-in;
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider .ns-sliderControl--next:active,
  .ns-slider .ns-sliderControl--next:hover,
  .ns-slider .ns-sliderControl--next:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 5px) 50%;
  }
}
@media (max-width: 767px) {
  .ns-slider .ns-sliderControl--next {
    position: relative;
    top: 0;
    transform: unset;
    float: right;
  }
}
.ns-slider--testimonialSlider .ns-slider__section {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(var(--spacePart) * 1);
}
.ns-slider--testimonialSlider .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-slider--testimonialSlider .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 17.11229947%;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .ns-slider--testimonialSlider .ns-slider__cover {
    padding: 0 16.11909651%;
  }
}
@media (max-width: 767px) {
  .ns-slider--testimonialSlider .ns-slider__cover {
    padding: 0;
  }
}
.ns-slider--testimonialSlider .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--testimonialSlider .ns-slider__area {
  float: left;
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
}
.ns-slider--testimonialSliderWithOpacity.ns-slider--testimonialSlider .ns-slider__area {
  width: calc(100% + 4px);
  margin-left: -2px;
}
.ns-slider--testimonialSliderOnlyMobile.ns-slider--testimonialSlider .ns-slider__area {
  flex-wrap: wrap;
  margin: -20px;
}
@media (max-width: 767px) {
  .ns-slider--testimonialSliderOnlyMobile.ns-slider--testimonialSlider .ns-slider__area {
    flex-wrap: nowrap;
    margin: 0 0 0 -20px;
  }
}
.ns-slider--testimonialSlider .ns-slide {
  float: left;
  width: calc((100%) - 40px);
  margin-left: 20px;
  margin-right: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: margin 0.6s;
}
.ns-slider--testimonialSlider .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
}
.ns-slider--testimonialSlider .ns-slider__part {
  float: left;
  width: 100%;
  margin: 10px 0;
}
.ns-slider--testimonialSlider .ns-slider__part--image {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: -10px;
}
.ns-slider--testimonialSlider .ns-slider__part--link {
  padding-top: 20px;
  margin-top: auto;
}
.ns-slider--testimonialSlider .ns-slider__part--link .open {
  float: left;
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 85px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider--testimonialSlider .ns-slider__part--link .open:active,
  .ns-slider--testimonialSlider .ns-slider__part--link .open:hover,
  .ns-slider--testimonialSlider .ns-slider__part--link .open:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
}
.ns-slider--LogoSlider .ns-slider__section {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(var(--spacePart) * 1);
}
.ns-slider--LogoSlider .ns-slider__section:first-child {
  margin-top: 0;
}
.ns-slider--LogoSlider .ns-slider__cover {
  float: left;
  width: 100%;
  position: relative;
  padding: 0 17.11229947%;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .ns-slider--LogoSlider .ns-slider__cover {
    padding: 0 16.11909651%;
  }
}
@media (max-width: 767px) {
  .ns-slider--LogoSlider .ns-slider__cover {
    padding: 0;
  }
}
.ns-slider--LogoSlider .ns-slider__stage {
  float: left;
  width: 100%;
  overflow: hidden;
}
.ns-slider--LogoSlider .ns-slider__area {
  float: left;
  width: calc(100% + 40px);
  margin-left: -20px;
  display: flex;
}
.ns-slider--LogoSliderWithOpacity.ns-slider--LogoSlider .ns-slider__area {
  width: calc(100% + 4px);
  margin-left: -2px;
}
.ns-slider--LogoSliderOnlyMobile.ns-slider--LogoSlider .ns-slider__area {
  flex-wrap: wrap;
  margin: -20px;
}
@media (max-width: 767px) {
  .ns-slider--LogoSliderOnlyMobile.ns-slider--LogoSlider .ns-slider__area {
    flex-wrap: nowrap;
    margin: 0 0 0 -20px;
  }
}
.ns-slider--LogoSlider .ns-slide {
  float: left;
  width: calc((100%/3) - 40px);
  margin-left: 20px;
  margin-right: 20px;
  flex-shrink: 0;
  box-sizing: border-box;
  transition: margin 0.6s;
}
@media (max-width: 1023px) {
  .ns-slider--LogoSlider .ns-slide {
    width: calc((100%/2) - 40px);
  }
}
.ns-slider--LogoSlider .ns-slide__content {
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
}
.ns-slider--LogoSlider .ns-slider__part {
  float: left;
  width: 100%;
  margin-top: var(--spacePart);
  margin-bottom: var(--spacePart);
}
.ns-slider--LogoSlider .ns-slider__part--sliderText {
  float: left;
  width: 100%;
  margin-top: calc(var(--spaceTotal) * 1);
  margin-bottom: calc(var(--spaceTotal) * 1);
  padding: 0 17.96875%;
  box-sizing: border-box;
}
@media (max-width: 1023px) {
  .ns-slider--LogoSlider .ns-slider__part--sliderText {
    padding: 0 16.11909651%;
    box-sizing: border-box;
  }
}
@media (max-width: 767px) {
  .ns-slider--LogoSlider .ns-slider__part--sliderText {
    padding: 0 0;
  }
}
.ns-slider--LogoSlider .ns-slider__part--link {
  padding-top: 20px;
  margin-top: auto;
}
.ns-slider--LogoSlider .ns-slider__part--link .open {
  float: left;
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  padding: 14px 85px 16px 30px;
  border-radius: 35px;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 1.42857143;
  letter-spacing: 0.002em;
  font-family: 'soehne-breit-kraftig', sans-serif;
  font-weight: normal;
  display: inline-block;
  transition: all 0.4s ease-in;
  background-size: 35px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
}
@media (hover: hover) and (pointer: fine) {
  .ns-slider--LogoSlider .ns-slider__part--link .open:active,
  .ns-slider--LogoSlider .ns-slider__part--link .open:hover,
  .ns-slider--LogoSlider .ns-slider__part--link .open:focus {
    background-color: #D26E46;
    background-size: 35px;
    background-position: calc(100% - 10px) 50%;
  }
}
/*# sourceMappingURL=./screen-small.css.map */